import { useEffect } from "react";

const useCustomfitViewport = (
  reactFlowWrapper,
  getNodes,
  setViewport,
  nodes,
  ...props
) => {
  useEffect(() => {
    const handleFitView = () => {
      const viewportWidth = reactFlowWrapper.current.clientWidth;

      const nodes = getNodes();

      if (nodes.length > 0) {
        const minX = Math.min(...nodes.map((node) => node.position.x));
        const maxX = Math.max(
          ...nodes.map((node) => node.position.x + node.width)
        );
        const totalWidth = maxX - minX;
        const scale = viewportWidth / totalWidth;
        setViewport({
          zoom: scale - 0.05,
          x: -80,
          y: 0,
        });
      }
    };

    handleFitView();
  }, [setViewport, getNodes, nodes]);
};

export default useCustomfitViewport;
