import { setGlobalState, useGlobalState } from "Global";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useCallback, useEffect, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import "css/workflow/workflow.css";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import WorkFlow from "examples/workflow/Workflow";
import { MarkerType, useEdgesState, useNodesState } from "reactflow";
import { getLayoutedElements } from "./custom/customDagre";
import useWorkflow from "./hooks/useWorkflow";
import { workflowService } from "./service/workflowService";
import CustomNode from "./custom/CustomNode";
import { downloadImage } from "layouts/analytics/utils";
import MDChip from "components/MDChip";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import UpdateNodeDialog from "./dialogs/UpdateNodeDialog";
import Confirmation from "examples/dialogs/Confirmation";
import Dropdown from "components/controls/Dropdown";
import { Dialog, Icon, Paper, TextField } from "@mui/material";
import AddNewWorkflowDialog from "./dialogs/AddNewWorkflowDialog";
import { ExpandingButton, OutlinedButton } from "components/MDButton/button";
import { features, files } from "./tempConst";
import FormBuilder from "examples/formBuilder/FormBuilder";
import AddFeatureAndFilesDialog from "./dialogs/AddFeatureAndFilesDialog";
import { controlTypes } from "components/controls/Controls";
import useCustomfitViewport from "./custom/useCustomfitViewport";

const nodeTypes = {
  custom: CustomNode,
};

function WorkflowView() {
  const styles = useStyles();

  const { palette, functions, typography } = useTheme();
  const { primary, error, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: primary.main,
    },
    pathOptions: { offset: 2 },
    style: {
      stroke: primary.main,
      strokeWidth: 2,
    },
    labelStyle: {
      fill: primary.main,
      fontSize: "12px",
      fontWeight: "bold",
    },
    labelBgStyle: {
      fill: primary.light,
      stroke: primary.main,
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 5,
    animated: true,
  };
  const {
    workflowDetail,
    setWorkflowDetail,
    workflows,
    setWorkflows,
    workflowView,
    isLoadingWorflowDetails,
    setIsLoadingWorflowDetails,
    state,
    setState,
    activeTab,
    setActiveTab,
    agents,
  } = useWorkflow();

  const AddWorkflowInputs = {
    name: {
      label: "Workflow Name",
      type: "Text",
      editable: true,
      required: true,
    },
    short_name: {
      label: "Workflow Short Name",
      type: "Text",
      editable: true,
      required: true,
    },
    init_agent: {
      label: "Initial Agent",
      type: "DropdownSingleSelect",
      options: agents,
      dataKey: "email_id",
      required: true,
      editable: true,
    },
    departments_list: {
      type: "MultiTagTextInput",
      required: true,
      editable: true,
    },
    workflow_first_step: {
      type: "Text",
      editable: true,
      required: true,
    },
    department: {
      label: "Workflow First Step Department",
      type: "Text",
      type: "DropdownSingleSelect",
      options: [],
      editable: true,
      required: true,
    },
  };
  const updateWorkflowInputs = useRef(null);

  const [addNodeInputs, setAddNodeInputs] = useState({
    node_name: {
      type: "Text",
      editable: true,
      required: true,
    },
    department: {
      type: "DropdownSingleSelect",
      options: [],
      editable: true,
      required: true,
    },
    auto_transfer_email: {
      label: "Agent",
      type: "DropdownSingleSelect",
      options: [],
      dataKey: "email_id",
      editable: true,
    },
    edgeLabel: {
      label: "Edge Label (Optional)",
      type: "Text",
      editable: true,
    },
  });

  useEffect(() => {
    setAddNodeInputs((prevState) => ({
      ...prevState,
      auto_transfer_email: {
        ...prevState.auto_transfer_email,
        options: agents,
      },
      department: {
        ...prevState.department,
        options: workflowDetail?.bucketPositions
          ? Object.keys(workflowDetail.bucketPositions)
          : [],
      },
    }));
  }, [agents, workflowDetail]);
  const updateNodeInputs = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddNodeDialog, setShowAddNodeDialog] = useState(false);
  const [showAddWorkflowDialog, setShowAddWorkflowDialog] = useState(false);
  const [showEditWorkflowDialog, setShowEditWorkflowDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showUpdateNodeDialog, setShowUpdateNodeDialog] = useState(false);
  const [showAddFeaturesDialog, setShowAddFeaturesDialog] = useState(false);
  const [showAddFilesDialog, setShowAddFilesDialog] = useState(false);
  const [clickedNodeId, setClickedNodeId] = useState(null);
  const [userDetails] = useGlobalState("userDetails");

  const addWorkflow = (data) => {
    const { department, ...restdata } = data;
    let workflow = {
      [data.workflow_first_step]: {
        auto_transfer_email: undefined,
        department: data.department,
        edges: {},
        features: features,
        files: files,
        prev_features: {},
        prev_files: {},
        rule_function: null,
      },
    };
    setIsLoading(true);
    workflowService
      .createNewWorkflow({
        ...restdata,
        workflow,
        permission: {},
        workflow_id: "test_id",
      })
      .then((res) => {
        setIsLoading(false);
        setShowAddWorkflowDialog(false);
        setWorkflows((pre) => [
          ...pre,
          { _id: res.data.workflow_id, name: res.data.name },
        ]);
        setActiveTab({ _id: res.data.workflow_id, name: res.data.name });
        handleWorkflowChange(res.data.workflow_id);
      })
      .catch((error) => {
        setGlobalState("error", {
          open: true,
          message: error.message,
          type: "error",
        });
        setIsLoading(false);
        setShowAddWorkflowDialog(false);
      });
  };
  const addChildNode = (parentNodeId, data) => {
    let tempWorkflowDetails = {
      ...workflowDetail,
      workflow: {
        ...workflowDetail.workflow,
      },
    };
    let parentfeatures = Object.keys(
      tempWorkflowDetails.workflow[parentNodeId]?.features || {}
    );
    let parentfiles = Object.keys(
      tempWorkflowDetails.workflow[parentNodeId]?.files || {}
    );
    tempWorkflowDetails.workflow[data.node_name.value] = {
      department: data.department.value,
      auto_transfer_email: data.auto_transfer_email.value,
      edges: {},
      features: {},
      files: {},
      prev_features:
        parentfeatures?.length > 0
          ? { [parentNodeId]: parentfeatures }
          : tempWorkflowDetails.workflow[parentNodeId]?.prev_features || {},
      prev_files:
        parentfiles?.length > 0
          ? { [parentNodeId]: parentfiles }
          : tempWorkflowDetails.workflow[parentNodeId]?.prev_files || {},
      steps_to_be_done_llm: {},
      rule_function: "string",
    };

    tempWorkflowDetails.workflow[parentNodeId] = {
      ...tempWorkflowDetails.workflow[parentNodeId],
      edges: {
        ...tempWorkflowDetails.workflow[parentNodeId]?.edges,
        [data?.edgeLabel?.value || "next_step"]: data.node_name.value,
      },
    };

    setIsLoading(true);
    workflowService
      .updateWorkflow({
        workflow_id: activeTab._id,
        workflow: tempWorkflowDetails.workflow,
      })
      .then((res) => {
        if (res?.status == 200) {
          setWorkflowDetail(tempWorkflowDetails);
          setGlobalState("workflowView", (prevView) => {
            let newView = { ...prevView };
            return { ...newView, [activeTab._id]: tempWorkflowDetails };
          });
        }
        setShowAddNodeDialog(false);
        setIsLoading(false);
        setClickedNodeId(null);
      })
      .catch((error) => {
        setGlobalState("error", {
          open: true,
          message: error.message,
          type: "error",
        });
        setShowAddNodeDialog(false);
        setIsLoading(false);
        setClickedNodeId(null);
      });
  };
  const updateNode = (data) => {
    let tempWorkflowDetails = {
      ...workflowDetail,
      workflow: {
        ...workflowDetail.workflow,
      },
    };

    tempWorkflowDetails.workflow[data.node_name] = {
      ...tempWorkflowDetails.workflow[data.node_name],
      department: data.department,
      auto_transfer_email: data.auto_transfer_email,
      edges: data?.edges || {},
    };
    setIsLoading(true);
    workflowService
      .updateWorkflowStep({
        workflow_id: activeTab._id,
        step_name: data.node_name,
        ...tempWorkflowDetails.workflow[data.node_name],
      })
      .then((res) => {
        if (res?.status == 200) {
          setWorkflowDetail(tempWorkflowDetails);
          setGlobalState("workflowView", (prevView) => {
            let newView = { ...prevView };
            return { ...newView, [activeTab._id]: tempWorkflowDetails };
          });
        }
        setShowUpdateNodeDialog(false);
        setIsLoading(false);
        setClickedNodeId(null);
      })
      .catch((error) => {
        setGlobalState("error", {
          open: true,
          message: error.message,
          type: "error",
        });
        setShowUpdateNodeDialog(false);
        setIsLoading(false);
        setClickedNodeId(null);
      });
  };
  const deleteNode = (id) => {
    let tempWorkflowDetails = workflowDetail;
    delete tempWorkflowDetails.workflow[id];
    for (const step of Object.keys(tempWorkflowDetails.workflow)) {
      let stepEdges = tempWorkflowDetails.workflow[step]["edges"];
      Object.keys(stepEdges).forEach((key) => {
        if (stepEdges[key] === id) {
          delete stepEdges[key];
        }
      });
      tempWorkflowDetails.workflow[step]["edges"] = stepEdges;
    }
    setIsLoading(true);
    workflowService
      .updateWorkflow({
        workflow_id: activeTab._id,
        workflow: tempWorkflowDetails.workflow,
      })
      .then((res) => {
        if (res?.status == 200) {
          initializeWorkflow(tempWorkflowDetails);
          setWorkflowDetail(tempWorkflowDetails);
          setGlobalState("workflowView", (prevView) => {
            let newView = { ...prevView };
            return { ...newView, [activeTab._id]: tempWorkflowDetails };
          });
        }
        setShowDeleteConfirmation(false);
        setIsLoading(false);
        setClickedNodeId(null);
      })
      .catch((error) => {
        setGlobalState("error", {
          open: true,
          message: error.message,
          type: "error",
        });
        setShowDeleteConfirmation(false);
        setIsLoading(false);
        setClickedNodeId(null);
      });
  };
  const handleAddFeatueresAndFiles = (data, type) => {
    let tempWorkflowDetails = {
      ...workflowDetail,
      workflow: {
        ...workflowDetail.workflow,
      },
    };
    tempWorkflowDetails.workflow[workflowDetail.workflow_first_step] = {
      ...tempWorkflowDetails.workflow[workflowDetail.workflow_first_step],
      [type]: data[type],
    };
    let keys = Object.keys(data[type]);
    Object.keys(tempWorkflowDetails.workflow).forEach((step) => {
      if (step !== workflowDetail.workflow_first_step) {
        tempWorkflowDetails.workflow[step] = {
          ...tempWorkflowDetails.workflow[step],
          [type === "features" ? "prev_features" : "prev_files"]: {
            [workflowDetail.workflow_first_step]: keys,
          },
        };
      }
    });
    setIsLoading(true);
    workflowService
      .updateWorkflow({
        workflow_id: activeTab._id,
        workflow: tempWorkflowDetails.workflow,
        permission: data?.permission || {},
      })
      .then((res) => {
        if (res?.status == 200) {
          setWorkflowDetail(tempWorkflowDetails);
          setGlobalState("workflowView", (prevView) => {
            let newView = { ...prevView };
            return { ...newView, [activeTab._id]: tempWorkflowDetails };
          });
        }
        setShowAddFeaturesDialog(false);
        setShowAddFilesDialog(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setGlobalState("error", {
          open: true,
          message: error.message,
          type: "error",
        });
        setShowAddFeaturesDialog(false);
        setShowAddFilesDialog(false);
        setIsLoading(false);
      });
  };

  const editWorkflow = (data) => {
    let tempWorkflowDetails = {
      ...workflowDetail,
      workflow: {
        ...workflowDetail.workflow,
      },
      bucketPositions: workflowService.getBucketPosition(data.departments_list),
      ...data,
    };
    setIsLoading(true);
    workflowService
      .updateWorkflow({
        workflow_id: activeTab._id,
        workflow: tempWorkflowDetails.workflow,
        ...data,
      })
      .then((res) => {
        if (res?.status == 200) {
          setWorkflowDetail(tempWorkflowDetails);
          setWorkflows((pre) => {
            let temp = pre.map((workflow) =>
              workflow._id === activeTab._id
                ? { ...workflow, name: tempWorkflowDetails.name }
                : workflow
            );
            return temp;
          });
          setActiveTab({ _id: activeTab._id, name: tempWorkflowDetails.name });
          setGlobalState("workflowView", (prevView) => {
            let newView = { ...prevView };
            return { ...newView, [activeTab._id]: tempWorkflowDetails };
          });
        }
        setShowEditWorkflowDialog(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setGlobalState("error", {
          open: true,
          message: error.message,
          type: "error",
        });
        setShowEditWorkflowDialog(false);
        setIsLoading(false);
      });
  };
  const handleEditWorkflowClick = () => {
    updateWorkflowInputs.current = {
      name: {
        label: "Workflow Name",
        type: "Text",
        editable: true,
        required: true,
        value: workflowDetail.name,
      },
      short_name: {
        label: "Workflow Short Name",
        type: "Text",
        editable: true,
        required: true,
        value: workflowDetail.short_name,
      },
      init_agent: {
        value: workflowDetail.init_agent,
        label: "Initial Agent",
        type: "DropdownSingleSelect",
        options: agents,
        dataKey: "email_id",
        required: true,
        editable: true,
      },
      departments_list: {
        value: workflowDetail.departments_list,
        type: "MultiTagTextInput",
        required: true,
        editable: true,
      },
    };
    setShowEditWorkflowDialog(true);
  };

  const onNodeClick = (id, type) => {
    if (type === "add") {
      setClickedNodeId(id);
      setShowAddNodeDialog(true);
    }
    if (type === "delete") {
      setClickedNodeId(id);
      setShowDeleteConfirmation(true);
    }
    if (type === "update") {
      setClickedNodeId(id);
      updateNodeInputs.current = {
        node_name: {
          type: "Text",
          editable: false,
          required: true,
          value: id,
        },
        department: {
          type: "DropdownSingleSelect",
          options: workflowDetail?.bucketPositions
            ? Object.keys(workflowDetail.bucketPositions)
            : [],
          editable: true,
          required: true,
          value: workflowDetail.workflow[id]?.department,
        },
        auto_transfer_email: {
          label: "Agent",
          type: "DropdownSingleSelect",
          options: agents,
          dataKey: "email_id",
          editable: true,
          value: workflowDetail.workflow[id]?.auto_transfer_email,
        },
      };
      setShowUpdateNodeDialog(true);
    }
  };

  const initializeWorkflow = (workflowDetail, highlightNode = null) => {
    workflowService
      .createWorkflowNodes(workflowDetail, highlightNode, onNodeClick)
      .then(({ nodes, edges }) => {
        getLayoutedElements(nodes, edges, workflowDetail.bucketPositions)
          .then(({ nodes, edges }) => {
            setNodes(nodes);
            setEdges(edges);
          })
          .catch((e) => {
            console.error(e);
          });
      })
      .catch((e) => {
        console.error(e);
      });
    setShowAddNodeDialog(false);
    setShowDeleteConfirmation(false);
    setClickedNodeId(null);
  };

  const handleWorkflowChange = (newValue) => {
    setState({ ...state, workflow: newValue });
    if (workflowView[newValue]) {
      setWorkflowDetail(workflowView[newValue]);
    } else {
      workflowService.getWorkflowDetails(newValue).then((data) => {
        setWorkflowDetail(data);
        setGlobalState("workflowView", (prevView) => {
          let newView = { ...prevView };
          return { ...newView, [newValue]: data };
        });
        setIsLoadingWorflowDetails(false);
      });
    }
  };

  useEffect(() => {
    if (
      workflowDetail.workflow &&
      Object.keys(workflowDetail.workflow).length != 0
    ) {
      initializeWorkflow(workflowDetail);
    }
  }, [workflowDetail?.workflow]);

  const [tags, setTags] = useState(["a", "b"]);
  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              account_tree
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            Workflow View
          </MDBox>
        }
        rightContent={
          userDetails?.permission === "superuser" && (
            <OutlinedButton
              name={"Add Workflow"}
              onClick={() => {
                setShowAddWorkflowDialog(true);
              }}
              icon={
                <Icon className={"font-unset"} fontSize="1em">
                  add
                </Icon>
              }
            />
          )
        }
      />
      <MDBox>
        <MDBox
          sx={{
            fontSize: typography.size["sm"],
            fontWeight: typography.fontWeightMedium,
            borderBottom: `1px solid ${palette.grey[300]}`,
            gap: "1em",
            display: "grid",
            gridTemplateColumns: `repeat(${workflows?.length}, 1fr)`,
          }}
          mt={2}
        >
          {workflows?.map((tab, index) => (
            <MDBox
              key={index}
              sx={{
                padding: pxToRem(8),
                cursor: "pointer",
                gap: "5px",
                textAlign: "center",
                paddingBottom: pxToRem(5),
                borderBottom:
                  activeTab.name === tab.name
                    ? `2px solid ${palette.primary.main}`
                    : ``,
              }}
              color={palette.text.header}
              onClick={() => {
                setActiveTab(tab);
                handleWorkflowChange(tab._id);
              }}
            >
              {tab.name}
            </MDBox>
          ))}
        </MDBox>
        <MDBox>
          {nodes && edges && workflowDetail.bucketPositions && (
            <MDBox
              mt={2}
              sx={{
                width: "100%",
                height: "calc(var(--h-100vh) - 200px)",
                minHeight: "70vh",
                position: "relative",
              }}
              key={nodes}
            >
              <WorkFlow
                fitView={false}
                useFitViewport={useCustomfitViewport}
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                zoomOnScroll={true}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                isAutoLayout={false}
                showBackground={true}
                showControls={true}
                edgeOptions={defaultEdgeOptions}
                nodesDraggable={true}
                minZoom={0.25}
                nodeStyle={`
                        padding: 0;
                        border: none;
                        width: 280px;
                      `}
                nodeDotStyle={`border:none; background-color: #00000000;`}
                key={edges + nodes}
              />
              <MDBox
                sx={{
                  position: "absolute",
                  fontSize: typography.size["sm"],
                  top: 0,
                }}
                color={error.main}
              >
                {Object.keys(
                  workflowDetail?.workflow[workflowDetail?.workflow_first_step]
                    .features
                ).length == 0 && "Form fields are missing"}{" "}
                <br />
                {Object.keys(
                  workflowDetail?.workflow[workflowDetail?.workflow_first_step]
                    .files
                ).length == 0 && "File inputs are missing"}
              </MDBox>
              <MDBox
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "50px",
                  marginTop: "15px",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  gap: "3px",
                }}
              >
                <ExpandingButton
                  iconPosition={"right"}
                  icon={
                    <span
                      class="material-symbols-outlined"
                      style={{ fontSize: "1.3em" }}
                    >
                      download
                    </span>
                  }
                  name={"Download Image"}
                  onClick={() =>
                    downloadImage(
                      document.querySelector(".react-flow__viewport"),
                      "workflow.png"
                    )
                  }
                />
                {userDetails?.permission === "superuser" && (
                  <>
                    <ExpandingButton
                      iconPosition={"right"}
                      icon={
                        <span
                          class="material-symbols-outlined"
                          style={{ fontSize: "1.3em" }}
                        >
                          edit_square
                        </span>
                      }
                      name={"Edit Form Fields"}
                      onClick={() => setShowAddFeaturesDialog(true)}
                    />
                    <ExpandingButton
                      iconPosition={"right"}
                      icon={
                        <span
                          class="material-symbols-outlined"
                          style={{ fontSize: "1.3em" }}
                        >
                          edit_document
                        </span>
                      }
                      name={"Edit File Inputs"}
                      onClick={() => setShowAddFilesDialog(true)}
                    />
                    <ExpandingButton
                      iconPosition={"right"}
                      icon={
                        <span
                          class="material-symbols-outlined"
                          style={{ fontSize: "1.3em" }}
                        >
                          rebase_edit
                        </span>
                      }
                      name={"Edit Workflow"}
                      onClick={() => handleEditWorkflowClick()}
                    />
                  </>
                )}
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <DataInputDialog
        open={showAddNodeDialog}
        handleClose={() => {
          setShowAddNodeDialog(false);
          setClickedNodeId(null);
        }}
        defaultInputs={addNodeInputs}
        handleSubmit={(data) => {
          addChildNode(clickedNodeId, data);
        }}
        buttonName={"Add Node"}
        heading={"Add New Node"}
        iconName={"add"}
        isLoading={isLoading}
      />
      {showUpdateNodeDialog && (
        <UpdateNodeDialog
          open={showUpdateNodeDialog}
          handleClose={() => {
            setShowUpdateNodeDialog(false);
            setClickedNodeId(null);
          }}
          defaultInputs={updateNodeInputs?.current}
          handleSubmit={(data) => {
            updateNode(data);
          }}
          defaultEdges={workflowDetail?.workflow[clickedNodeId]?.edges}
          workflowSteps={Object.keys(workflowDetail?.workflow)}
          buttonName={"Update Node"}
          heading={"Update Node"}
          iconName={"update"}
          isLoading={isLoading}
        />
      )}
      <Confirmation
        open={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
          setClickedNodeId(null);
        }}
        onAccept={() => deleteNode(clickedNodeId)}
        acceptLable={"Delete"}
        title={`Do you really want to delete Node: ${clickedNodeId}?`}
        isLoading={isLoading}
      />
      {showAddWorkflowDialog && (
        <AddNewWorkflowDialog
          open={showAddWorkflowDialog}
          handleClose={() => {
            setShowAddWorkflowDialog(false);
          }}
          handleSubmit={(data) => {
            addWorkflow(data);
          }}
          buttonName={"Add New Workflow"}
          heading={"Add Workflow"}
          iconName={"add"}
          isLoading={isLoading}
          agents={agents}
          defaultInputs={AddWorkflowInputs}
        />
      )}
      {showEditWorkflowDialog && (
        <AddNewWorkflowDialog
          open={showEditWorkflowDialog}
          handleClose={() => {
            setShowEditWorkflowDialog(false);
          }}
          handleSubmit={(data) => {
            editWorkflow(data);
          }}
          buttonName={"Edit Workflow"}
          heading={"Edit Workflow"}
          iconName={"edit"}
          isLoading={isLoading}
          agents={agents}
          defaultInputs={updateWorkflowInputs.current}
        />
      )}
      {workflowDetail?.workflow && (
        <>
          <AddFeatureAndFilesDialog
            open={showAddFeaturesDialog}
            heading={"Edit Form Fields (" + workflowDetail.name + ")"}
            handleClose={() => {
              setShowAddFeaturesDialog(false);
            }}
            handleSubmit={(data) => {
              handleAddFeatueresAndFiles(data, "features");
            }}
            defaultInputs={JSON.parse(
              JSON.stringify(
                workflowDetail?.workflow[workflowDetail.workflow_first_step]
                  ?.features
              )
            )}
            inputTypes={[
              controlTypes.text,
              controlTypes.dropdown,
              controlTypes.integer,
              controlTypes.date,
            ]}
            permission={{ ...workflowDetail?.permission }}
            activeStep={workflowDetail?.workflow_first_step}
            type="features"
            isLoading={isLoading}
          />
          <AddFeatureAndFilesDialog
            open={showAddFilesDialog}
            heading={"Edit File Inputs (" + workflowDetail.name + ")"}
            handleClose={() => {
              setShowAddFilesDialog(false);
            }}
            handleSubmit={(data) => {
              handleAddFeatueresAndFiles(data, "files");
            }}
            defaultInputs={JSON.parse(
              JSON.stringify(
                workflowDetail?.workflow[workflowDetail.workflow_first_step]
                  ?.files
              )
            )}
            inputTypes={[controlTypes.files]}
            permission={{ ...workflowDetail?.permission }}
            activeStep={workflowDetail?.workflow_first_step}
            type="files"
            isLoading={isLoading}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default WorkflowView;
